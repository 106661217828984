import 'alpinejs';

window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
    window.Dropzone = require("dropzone");
    window.exifjs = require("exif-js");
    window.moment = require("moment");
    window.Pikaday = require("pikaday");
} catch (e) {}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
